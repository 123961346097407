<template>
    <div>
        <ts-page-title
            :title="$t('role.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('role.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div
                    class="tw-flex tw-items-center"
                    :class="
                        $can('create-roles')
                            ? 'tw-justify-between'
                            : 'tw-justify-end'
                    "
                >
                    <a-button
                        v-if="$can('create-roles')"
                        type="primary"
                        @click="$router.push({ name: 'role-create' })"
                    >
                        {{ $t("addNew") }}
                    </a-button>
                    <a-input-search
                        v-model="search"
                        :placeholder="$t('role.search')"
                        style="width: 200px"
                    />
                </div>
            </ts-panel-wrapper>
            <ts-table
                :columns="columns"
                :records="resources"
                :loading="loading"
                :rowGroup="{
                    field: 'role_type'
                }"
            >
                <template v-slot="{ record }">
                    <td class="tw-capitalize">{{ record.name }}</td>
                    <td class="text-center tw-space-x-3">
                        <a
                            v-if="$can('update-roles')"
                            href="#"
                            v-tooltip="$t('edit')"
                            class="text-primary mr-2"
                            @click.prevent="edit(record)"
                        >
                            <i class="fas fa-edit"></i>
                        </a>
                        <a-popconfirm
                            v-if="$can('delete-roles')"
                            placement="leftTop"
                            :title="$t('are_you_sure_to_delete')"
                            :ok-text="$t('yes')"
                            :cancel-text="$t('no')"
                            @confirm="deleteRecord(record)"
                        >
                            <a href="#" :disabled="record._deleting">
                                <i
                                    class="fas fa-circle-notch spin ml-2"
                                    v-if="record._deleting"
                                ></i>
                                <i class="fa fa-trash ml-2 text-red" v-else></i>
                            </a>
                        </a-popconfirm>
                    </td>
                </template>
            </ts-table>
            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
        </ts-panel>
    </div>
</template>

<script>
import { debounce } from "lodash";
import { mapState } from "vuex";

export default {
    name: "roleIndex",
    data() {
        return {
            loading: false
        };
    },
    computed: {
        ...mapState("auth/role", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.auth.role.search;
            },
            set(newValue) {
                this.$store.commit("auth/role/SET_SEARCH", newValue);
                this.$store.commit("auth/role/RESET_CURRENT_PAGE");
            }
        },
        columns() {
            return [
                {
                    name: this.$t("role.roleName"),
                    sortKey: "name"
                },
                {
                    name: this.$t("actions"),
                    style: "text-align:center; width: 1px;"
                }
            ];
        }
    },
    methods: {
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("auth/role/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$toasted.error(error.message);
                });
        },
        edit(record) {
            this.$store.commit("auth/role/SET_EDIT_DATA", record);
            this.$router.push({ name: "role-edit", params: { id: record.id } });
        },
        async deleteRecord(record) {
            record._deleting = true;
            this.$store
                .dispatch("auth/role/destroy", record.id)
                .then(response => {
                    this.fetchData();
                    this.$toasted.success(response.message);
                    record._deleting = false;
                })
                .catch(error => {
                    this.$toasted.error(error.message);
                    record._deleting = false;
                });
        }
    },
    watch: {
        search: debounce(function(search) {
            this.fetchData({ search });
        }, 500)
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("auth/role/RESET_STATE");
        next();
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    }
};
</script>
