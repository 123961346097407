var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('role.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('role.pageTitle'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('div',{staticClass:"tw-flex tw-items-center",class:_vm.$can('create-roles')
                        ? 'tw-justify-between'
                        : 'tw-justify-end'},[(_vm.$can('create-roles'))?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'role-create' })}}},[_vm._v(" "+_vm._s(_vm.$t("addNew"))+" ")]):_vm._e(),_c('a-input-search',{staticStyle:{"width":"200px"},attrs:{"placeholder":_vm.$t('role.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('ts-table',{attrs:{"columns":_vm.columns,"records":_vm.resources,"loading":_vm.loading,"rowGroup":{
                field: 'role_type'
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var record = ref.record;
return [_c('td',{staticClass:"tw-capitalize"},[_vm._v(_vm._s(record.name))]),_c('td',{staticClass:"text-center tw-space-x-3"},[(_vm.$can('update-roles'))?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('edit')),expression:"$t('edit')"}],staticClass:"text-primary mr-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.edit(record)}}},[_c('i',{staticClass:"fas fa-edit"})]):_vm._e(),(_vm.$can('delete-roles'))?_c('a-popconfirm',{attrs:{"placement":"leftTop","title":_vm.$t('are_you_sure_to_delete'),"ok-text":_vm.$t('yes'),"cancel-text":_vm.$t('no')},on:{"confirm":function($event){return _vm.deleteRecord(record)}}},[_c('a',{attrs:{"href":"#","disabled":record._deleting}},[(record._deleting)?_c('i',{staticClass:"fas fa-circle-notch spin ml-2"}):_c('i',{staticClass:"fa fa-trash ml-2 text-red"})])]):_vm._e()],1)]}}])}),_c('div',{staticClass:"d-flex justify-content-end tw-p-4"},[_c('ts-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }